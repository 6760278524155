<template>
  <div class="register-page" @keydown="onKeyDown">
    <el-container>
      <el-header style="padding: 0">
        <BaseHeader back-path="/auth/login" :contain-avatar="false">
          <template v-slot:left>
            <span>注册用户</span>
          </template>
        </BaseHeader>
      </el-header>
      <el-main class="register-main">
        <div class="register-body">
          <div class="register-block">
            <el-form :model="form" :rules="rules" ref="registerForm" :disabled="isFinished" v-loading="loading">
              <el-form-item label="用户名" prop="username">
                <el-input v-model="form.username" />
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input type="password" autocomplete="new-password" v-model="form.password" show-password />
              </el-form-item>
              <el-form-item label="确认密码" prop="confirmPassword">
                <el-input type="password" v-model="form.confirmPassword" show-password />
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input type="email" v-model="form.email" />
              </el-form-item>
              <el-form-item label="验证码" prop="verifyCode">
                <BaseInputVerifyCode v-model="form.verifyCode" :email="form.email" />
              </el-form-item>
              <el-form-item>
                <base-row>
                  <el-button type="primary" @click="onSubmit">注册</el-button>
                </base-row>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader'
import BaseInputVerifyCode from '@/components/BaseInputVerifyCode'
import api from '@/api'
import { dealOtherError } from '@/api/error'
import rule from '@/validate/rule'
import { APICode } from '@/api/common'

export default {
  name: 'Register',
  components: {
    BaseHeader,
    BaseInputVerifyCode
  },
  data () {
    return {
      form: {
        username: '',
        password: '',
        email: '',
        verifyCode: '',
        confirmPassword: ''
      },
      rules: {
        username: rule.username,
        password: rule.password,
        email: rule.email,
        verifyCode: rule.verifyCode,
        confirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
                return
              }
              if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致'))
                return
              }
              callback()
            }
          }
        ]
      },
      isFinished: false,
      loading: false
    }
  },
  methods: {
    onSubmit () {
      this.$refs.registerForm.validate((valid) => {
        if (!valid) {
          this.$message.error('格式错误！请检查数据格式')
          return false
        }
        this.register()
      })
    },
    async register () {
      try {
        this.loading = true
        await api.register(this.form.username, this.form.password, 'email',
          this.form.email, this.form.verifyCode)
        this.isFinished = true
        this.$message.success('注册成功，3秒后返回到登录界面')
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 3000)
      } catch (error) {
        if (error.resp) {
          const resp = error.resp
          if (resp.code === APICode.Exist) {
            if (resp.message.includes('username')) {
              this.$message.error('用户名已被注册！请修改用户名')
              return
            }
            if (resp.message.includes('email')) {
              this.$message.error('邮箱已注册！')
              return
            }
          }
          if (resp.code === APICode.ValidateFailed) {
            this.$message.error('验证码错误或者已过期！')
            return
          }
        }
        dealOtherError(this, error)
      } finally {
        this.loading = false
      }
    },
    onKeyDown (event) {
      if (event.key === 'Enter' && !this.loading && !this.isFinished) {
        this.onSubmit()
      }
    }
  }
}
</script>

<style scoped>
.register-page {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.register-main {
  background-color: rgb(249, 249, 249);
}

.register-body {
  display: flex;
  justify-content: center;
}

.register-block {
  padding: 20px 50px 20px 50px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px, rgba(0, 0, 0, .06) 0 1px 1px;
  background-color: white;
}

.el-button {
  flex-grow: 1;
}
</style>
